import Dropdown from "./components/dropdown";

let inputsForm = document.querySelectorAll('[page-input]');
[].forEach.call(inputsForm, function(input) {


	input.addEventListener('input', function(event) {

		setTimeout(() => {	
			if(input.value) {
				input.classList.add('is-filled');
			} else {
				input.classList.remove('is-filled');
			}
		})

	});
});

const dropDown = document.querySelector('[data-dropdown]');
new Dropdown(dropDown);

document.addEventListener('click', e => {
    if (!e.target.closest('[data-dropdown]')) {
        dropDown.classList.remove('on-focus');
    }
})
