export default class Dropdown {
    constructor(ref) {
        this.ref = ref;
        const dataset = this.ref.dataset;
        this.options = JSON.parse(dataset.dropdownOptions);
        this.placeholder = dataset.dropdownPlaceholder;
		this.readOnly = dataset.dropdownReadonly ?? false;
        this.name = dataset.dropdownName ?? 'genericDropdown';
        this.data = {key: '', value: ''};
        this.ref.innerHTML = this.render();
        this.visibleInput = this.ref.querySelector('[data-dropdown-input]');
        this.hiddenInput = this.ref.querySelector('[data-dropdown-value]');
        this.optionsList = this.ref.querySelector('[data-dropdown-list]');
        this.addEventListeners();    
    }

    render() {
        return /*html*/ `
            ${this.renderInputs()}
            <div data-dropdown-arrow=""></div>
            <div data-dropdown-list>
                ${this.renderOptions()}
            </div>
			`
        
    }

    renderInputs() {
        return /*html*/ `
            <input data-dropdown-input ${this.readOnly ? 'readonly' : ''} name="${this.name}-visible" placeholder="${this.placeholder}">
            <input data-dropdown-value type="hidden" name="${this.name}">
			`
         
    }

    renderOptions(options = this.options) {
        
        return options.reduce((html, option) => {
				return html += /*html*/ `<button type="button" data-dropdown-option data-dropdown-value="${option.value}">${option.key}</button>`
				}, '')
    }

    addEventListeners() {
        //    this.visibleInput.addEventListener('focus', this.handleInputFocus.bind(this));
        //    this.visibleInput.addEventListener('input', this.handleInputInput.bind(this));
        //    this.visibleInput.addEventListener('blur', this.handleInputBlur.bind(this));

        this.ref.addEventListener('click', this.handleClick.bind(this));
        this.optionsList.addEventListener('mousedown', this.handleOptionClick.bind(this));

    }

    handleInputFocus() {
       this.ref.classList.add('on-focus');
	   if (!this.readOnly) {
			this.visibleInput.placeholder = 'Поиск по названию';
       		this.visibleInput.value = '';
	   }
       
        document.dispatchEvent(new CustomEvent('dropdown::gotFocus', {detail:{el: this.ref}}))
    }

    handleInputBlur() {
       this.ref.classList.remove('on-focus');
       this.visibleInput.placeholder = this.placeholder;
       this.visibleInput.value = this.data.key;
        document.dispatchEvent(new CustomEvent('dropdown::lostFocus', {detail:{el: this.ref}}))
       
    }

    handleOptionClick({target}) {

        const btn = target.closest('[data-dropdown-option]');
        this.setOption({
            key: btn.innerText,
            value: btn.dataset.dropdownValue
        });
    }

    handleClick()  {
        this.ref.classList.toggle('on-focus');
    }

    setOption({key, value}) {
        this.visibleInput.value = key;
        this.hiddenInput.value = value;
        this.data = {key: key, value: value};
        document.dispatchEvent(new CustomEvent('dropdown::optionSet', {detail: {
            el: this.ref,
            data: this.data
        }}))
    }

    handleInputInput({target}) {
        const options = this.filterOptions(target.value);
        if (options.length) {
            this.optionsList.innerHTML = this.renderOptions(options);
        }
        else {
            this.optionsList.innerHTML = '<div data-dropdown-option class="is-disabled">Ничего не найдено</div>'
        }
        
    }
    
    filterOptions(query) {
        return this.options.filter(option => ~option.key.toLowerCase().indexOf(query.toLowerCase()));
    }
    
}

